import React, { useState } from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import Hero from "../components/Hero/HeroSplit";
import Testimonials from "../components/Repeating/Testimonials";
import Resources from "../components/Repeating/ResourcesAlt";
import CallToAction from "../components/Repeating/CTA";
import ButtonSolid from "../components/Button/ButtonSolid";
import ModalLoanOptions from "../components/Modal/ModalLoanOptions";

import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion";

const Page = ({ data }) => {
  const [slideIndex, setSlideIndex] = useState(0);

  const faqs = [
    {
      question: "What is an APR?",
      answer: (
        <>
          <p>
            APR refers to the “annual percentage rate.” It’s the cost of a
            mortgage on an annual basis and is likely to be higher than the
            stated note rate because it also takes into account points and other
            credit costs.
          </p>
          <p>
            The value of knowing the APR is that it allows home buyers to
            compare different types of mortgages based on the loan’s annual
            cost. In other words, it measures the true cost of a loan. It’s also
            important to note that it doesn’t affect your monthly mortgage
            payments.
          </p>
        </>
      ),
    },
    {
      question: "What does it mean to lock the interest rate?",
      answer: (
        <>
          <p>
            Interest rates can change from day to day, and locking in your rate
            keeps you from having to ride the wave. A sharp rise in an interest
            rate can increase a mortgage payment, so lenders can allow a
            borrower to lock in an interest rate. The rate is locked for a
            specific period of time, usually 30 to 60 days, and sometimes there
            is a fee.
          </p>
        </>
      ),
    },
    {
      question: "Should I pay points to lower my interest rate?",
      answer: (
        <>
          <p>
            If you plan to stay in your home for at least a few years, then
            paying for points to lower the interest rate can help you keep your
            monthly loan payment where you want it. Points can also help you
            qualify for a bigger loan amount.
          </p>
          <p>
            If you don’t plan on living in the home for more than a year or two,
            it’s probably best not to consider purchasing points. That’s because
            your monthly savings may not make up the cost you paid upfront.
          </p>
        </>
      ),
    },
    {
      question: "How do lenders judge my credit?",
      answer: (
        <>
          <p>
            Lenders use a credit report to get a complete picture of your
            finances and ensure you are a responsible borrower. They’ll look at
            your credit report for indications of your bill-paying history, any
            late payments or collections that may be on your report, the number
            of accounts (and type of accounts) you have, and the age of your
            accounts.
          </p>
        </>
      ),
    },
    {
      question: "What is PMI (Private Mortgage Insurance)?",
      answer: (
        <>
          <p>
            Lenders use Private Mortgage Insurance (PMI) to protect themselves
            if you can't pay your mortgage and end up defaulting. You'll
            typically be required to purchase PMI if your down payment is less
            than 20%.
          </p>
        </>
      ),
    },
  ];

  const loans = [
    {
      id: 0,
      title: "Fixed Rate Mortgages",
      excerpt:
        "Your monthly principal and interest rate stays the same during the life of the loan.",
      description: (
        <>
          <p>
            A fixed rate mortgage does not fluctuate throughout the life of the
            loan. The interest rate and principal stay the same throughout the
            entire term (terms are typically between 10 to 30 years), and
            there’s no penalty for paying it off early.
          </p>

          <p>
            Some home buyers like to shorten their loan with a “bi-weekly”
            mortgage where you pay every two weeks instead of monthly. That
            results in you paying 13 months of payments annually rather than 12
            months. It’s an easy way to get ahead on your loan over time.
          </p>
        </>
      ),
      category: ["All", "Easy Qualification", "Rate Security"],
    },
    {
      id: 1,
      title: "Adjustable Rate Mortgages (ARM)",
      excerpt:
        "The interest rate shifts periodically, increasing or lowering your monthly payment.",
      description: (
        <>
          <p>
            With an Adjustable Rate Mortgage (ARM), the interest rate is
            initially fixed and later adjusted to current market conditions.
            Some home buyers prefer an ARM because the lower initial interest
            rate allows them to buy a more expensive home.
          </p>
          <p>
            The initial fixed rate can run between 1 month to 10 years,
            depending on the loan. And some ARM loans will allow you to convert
            to a fixed rate if you decide to switch.
          </p>
        </>
      ),
      category: ["All", "Easy Qualification", "Loan Flexibility"],
    },
    {
      id: 2,
      title: "Hybrid ARMs",
      details: "(3/1 ARM, 5/1 ARM, 7/1 ARM, 10/1 ARM)",
      excerpt:
        "The interest rate is initially fixed, but is followed by a period with an adjustable rate.",
      description: (
        <>
          <p>
            A hybrid loan is a blend of a fixed rate and an adjustable rate. It
            begins as a fixed rate loan and then later is converted to an
            adjustable rate loan. For example, a 30-year hybrid loan may be
            designed with a five-year fixed rate and an adjustable rate for 25
            years.
          </p>
        </>
      ),
      category: ["All", "Loan Flexibility"],
    },
    {
      id: 3,
      title: "FHA Loans",
      excerpt:
        "A loan that’s insured against default by the Federal Housing Administration (FHA)",
      description: (
        <>
          <p>
            An FHA loan is guaranteed by the government and is typically a good
            option for first-time home buyers or those with a spotty credit
            history. The benefits include a smaller down payment, lower interest
            rates, it’s easier on credit-challenged borrowers, and 100% of the
            down payment can be in the form of a gift.
          </p>
        </>
      ),
      category: ["All", "Easy Qualification", "Lower Equity / Money Down"],
    },
    {
      id: 4,
      title: "VA Loans",
      excerpt:
        "A guaranteed home loan for veterans that requires no down payment.",
      description: (
        <>
          <p>
            Active service members, veterans, and their surviving spouses can
            purchase a home with a VA loan, which the U.S. Department of
            Veterans Affairs offers. To qualify, the VA will verify military
            status. The benefits include no down payment, no minimum credit
            score, you’re not required to pay Private Mortgage Insurance (PMI),
            and you can pay off the loan at any time without penalty.
          </p>
        </>
      ),
      category: ["All", "Lower Equity / Money Down"],
    },
    {
      id: 5,
      title: "Interest Only Mortgages",
      excerpt:
        "The monthly payment doesn’t include the repayment of principal for a certain period of time.",
      description: (
        <>
          <p>
            An interest only loan means your monthly mortgage payment doesn’t
            include principal for a period of time. When the interest only
            period ends, your monthly payments significantly increase with the
            addition of principal. This is an option for those who may want to
            buy a more expensive home with a lower monthly payment out of the
            gate.
          </p>
        </>
      ),
      category: ["All", "Lower Equity / Money Down"],
    },
    {
      id: 6,
      title: "Balloon Mortgages",
      excerpt:
        "An initial fixed interest rate, then a balloon payment due at the end of the term.",
      description: (
        <>
          <p>
            With a balloon mortgage, once the initial fixed interest rate period
            ends, a large payment for the remaining balance of the principal is
            due. At that point, the borrower can either pay off the balance or
            refinance. Terms are typically between five and seven years.
          </p>
        </>
      ),
      category: ["All", "Loan Flexibility"],
    },
    {
      id: 7,
      title: "Reverse Mortgages",
      excerpt:
        "Allows seniors to convert a portion of home equity into cash while living in their home.",
      description: (
        <>
          <p>
            With a reverse mortgage, the lender pays the homeowner (you have to
            be 62 or older to qualify) each month using the home’s equity. And
            as long as the homeowner remains in the home, there are no fees, and
            no principal or interest is due.
          </p>
        </>
      ),
      category: ["All", "Loan Flexibility"],
    },
    {
      id: 8,
      title: "Graduated Payment Mortgages",
      excerpt:
        "Your monthly principal and interest rate stays the same during the life of the loan.",
      description: (
        <>
          <p>
            A graduated payment mortgage can make sense when interest rates are
            high, pushing the cost of a monthly mortgage payment up.{" "}
          </p>
          <p>
            Borrowers should note that the interest owed will increase over
            time, and the payment shortfall at the beginning could potentially
            lead to “negative amortization” (you might end up owing more on your
            home than it’s worth).
          </p>
        </>
      ),
      category: ["All", "Rate Security"],
    },
  ];

  const [setCategory, setCategoryState] = useState("All");
  // const [setActive, setActiveState] = useState(false);

  const categoryFilterClickHandler = (category) => {
    setCategoryState(category);
    // setActiveState(!setActive);
  };

  const filteredProducts = loans.filter((loan) =>
    loan.category.includes(setCategory)
  );

  return (
    <Layout>
      <SearchEngineOptimization
        title="Minnesota Home Loan Programs | Thistle Financial"
        description="Compare Minnesota home loan programs, whether it's a traditional FHA loan or one that's a little more elaborate like a Hybrid ARM mortgage."
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <Hero
        image={data.heroDesktop.childImageSharp.gatsbyImageData}
        imageAlignment="right"
        textWidth="1/2"
        imageWidth="1/2"
        minHeight="md:min-h-[626px]"
        className="mb-16 md:mb-0"
      >
        <h1>Minnesota Home Loan Programs</h1>
        <p>
          There are loan options for every type of buyer, and we work with
          multiple lenders to find the right one for you, whether it’s a
          traditional FHA loan or something a little more elaborate, like a
          Hybrid ARM mortgage.
        </p>
        <ButtonSolid href="/request-rates/" text="Request Rates" />
      </Hero>

      <section className="mb-20 md:mb-32 bg-primary-50 py-20 md:py-32">
        <div className="container">
          <header className="text-center mb-8 md:mb-10">
            <h2>Your Loan Options</h2>
          </header>

          <div className="hidden md:flex justify-center space-x-8 lg:space-x-10 mb-8">
            <button
              onKeyDown={() => categoryFilterClickHandler("All")}
              onClick={() => categoryFilterClickHandler("All")}
              className="text-primary-400/60 hover:text-primary-400 text-sm lg:text-base tracking-wider font-semibold"
              // className={`hover:text-primary-400 text-sm lg:text-base tracking-wider font-semibold ${
              //   setActive ? "text-primary-400" : "text-primary-400/60"
              // }`}
            >
              All
            </button>
            <button
              onKeyDown={() => categoryFilterClickHandler("Easy Qualification")}
              onClick={() => categoryFilterClickHandler("Easy Qualification")}
              className="text-primary-400/60 hover:text-primary-400 text-sm lg:text-base tracking-wider font-semibold"
              // className={`hover:text-primary-400 text-sm lg:text-base tracking-wider font-semibold ${
              //   setActive ? "text-primary-400" : "text-primary-400/60"
              // }`}
            >
              Easy Qualification
            </button>
            <button
              onKeyDown={() => categoryFilterClickHandler("Loan Flexibility")}
              onClick={() => categoryFilterClickHandler("Loan Flexibility")}
              className="text-primary-400/60 hover:text-primary-400 text-sm lg:text-base tracking-wider font-semibold"
              // className={`hover:text-primary-400 text-sm lg:text-base tracking-wider font-semibold ${
              //   setActive ? "text-primary-400" : "text-primary-400/60"
              // }`}
            >
              Loan Flexibility
            </button>
            <button
              onKeyDown={() => categoryFilterClickHandler("Rate Security")}
              onClick={() => categoryFilterClickHandler("Rate Security")}
              className="text-primary-400/60 hover:text-primary-400 text-sm lg:text-base tracking-wider font-semibold"
              // className={`hover:text-primary-400 text-sm lg:text-base tracking-wider font-semibold ${
              //   setActive ? "text-primary-400" : "text-primary-400/60"
              // }`}
            >
              Rate Security
            </button>
            <button
              onKeyDown={() =>
                categoryFilterClickHandler("Lower Equity / Money Down")
              }
              onClick={() =>
                categoryFilterClickHandler("Lower Equity / Money Down")
              }
              className="text-primary-400/60 hover:text-primary-400 text-sm lg:text-base tracking-wider font-semibold"
              // className={`hover:text-primary-400 text-sm lg:text-base tracking-wider font-semibold ${
              //   setActive ? "text-primary-400" : "text-primary-400/60"
              // }`}
            >
              Lower Equity / Money Down
            </button>
          </div>

          <div className="grid md:grid-cols-2 lg:grid-cols-3 md:gap-x-8 gap-y-6">
            {filteredProducts.map((loan, i) => {
              return (
                <button
                  className="bg-white pt-8 px-6 pb-3.5 shadow-3xl border border-black/20 rounded hover:border-transparent hover:outline-none hover:ring-3 hover:ring-primary-400 hover:ring-opacity-30 text-left"
                  aria-label="Modal trigger"
                  data-modal-open="modal-loan-options"
                  onClick={() => setSlideIndex(loan.id)}
                  key={i}
                >
                  <p className="text-xl font-bold mb-3">{loan.title}</p>
                  <div className="w-18 h-0.5 bg-primary-600 mb-3"></div>
                  <p className="text-gray-400 text-sm mb-10">{loan.excerpt}</p>

                  <div className="flex items-center justify-end -mr-6 space-x-3">
                    <span className="text-primary-400 text-sm tracking-wider uppercase">
                      Learn More
                    </span>
                    <div className="bg-primary-400 w-4 h-0.5"></div>
                  </div>
                </button>
              );
            })}
          </div>
        </div>
      </section>

      <section className="mb-20 md:mb-32">
        <div className="container">
          <header className="mb-12 md:mb-16">
            <h2>Frequently Asked Questions</h2>
          </header>

          <Accordion
            allowZeroExpanded={true}
            className="border-t border-solid border-primary-600/20"
          >
            {faqs.map((faq, i) => {
              return (
                <div key={i}>
                  <AccordionItem
                    className="border-b border-solid border-primary-600/20 pt-6 md:pt-7 pb-5 md:pb-6"
                    uuid={i}
                  >
                    <AccordionItemButton className="flex items-center justify-between hover:outline-none">
                      <p className="font-heading text-xl md:text-3xl font-bold mb-0">
                        {faq.question}
                      </p>
                      <AccordionItemState>
                        {(state) => {
                          const icon = state.expanded
                            ? "fa-chevron-up"
                            : "fa-chevron-down";
                          return (
                            <i
                              className={`far ${icon} text-lg text-primary-400 ml-4`}
                            ></i>
                          );
                        }}
                      </AccordionItemState>
                    </AccordionItemButton>
                    <AccordionItemPanel className="pt-6 pr-10 max-w-4xl animate-fadeIn">
                      <p className="mb-0">{faq.answer}</p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
              );
            })}
          </Accordion>
        </div>
      </section>

      <Testimonials />
      <Resources />
      <CallToAction />

      <ModalLoanOptions slideIndex={slideIndex} slides={loans} />
    </Layout>
  );
};

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "open-graph/facebook/Loans Programs.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "open-graph/twitter/Loans Programs.jpg" }
    ) {
      publicURL
    }
    heroDesktop: file(
      relativePath: { eq: "2.5 Loan Programs/Mask Group.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 680)
      }
    }
  }
`;
export default Page;
