import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Link from "gatsby-plugin-transition-link";

const ValueProps = ({ className }) => {
  const data = useStaticQuery(graphql`
    {
      mortgageBasics: file(
        relativePath: { eq: "repeating/resources/2.1 Mortgage basics.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 373)
        }
      }
      calculators: file(
        relativePath: { eq: "repeating/resources/2.2 calc.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 373)
        }
      }
      closing: file(
        relativePath: { eq: "repeating/resources/2.3 5 steps.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 373)
        }
      }
    }
  `);

  const content = [
    {
      image: data.mortgageBasics.childImageSharp.gatsbyImageData,
      heading: "Mortgage Basics",
      text: "Learn about applying for a loan, how your credit score fits in, appraisals, closing costs, and more.",
      link: "/home-mortgage-basics/",
    },
    {
      image: data.calculators.childImageSharp.gatsbyImageData,
      heading: "Mortgage Calculators",
      text: "How much home can you afford? What would your monthly payment be? Find out with our calculators.",
      link: "/mortgage-calculators/",
    },
    {
      image: data.closing.childImageSharp.gatsbyImageData,
      heading: "5-Step Loan Process",
      text: "The loan process can be daunting, but we’re here to help. We break it down into five simple steps.",
      link: "/loan-process/",
    },
  ];

  return (
    <section className={`${className || "mb-20 md:mb-32"}`}>
      <div className="container">
        <header className="mb-8 md:mb-10 text-center">
          <h2 className="mb-0">Your Mortgage Resource Center</h2>
        </header>
        <div className="grid md:grid-cols-3 gap-y-8 md:gap-x-5 lg:gap-x-10 mb-12 md:mb-0">
          {content.map((content, i) => {
            return (
              <Link to={content.link} className="group" key={i}>
                <div className="overflow-hidden  mb-4">
                  <GatsbyImage
                    image={content.image}
                    alt={content.heading}
                    className="transform scale-100 md:group-hover:scale-110 transition-all duration-500 ease-linear"
                  />
                </div>

                <h3 className="heading-four mb-2">{content.heading}</h3>
                <p className="mb-0">{content.text}</p>
              </Link>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default ValueProps;
